<template>
	<div class="jujianlist" style="background:#F0F3F8">
		<div class="left">
			<div class="action">
				<div class="actionitem hotcity">
					<div class="aleft">城&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;市：</div>
					<div class="aright">
						<div @click="changeurl(item.id, hotcity, 'city_id')" :class="{ sinle: true, checked: item.checked }"
							v-for="item in hotcity" :key="item.id">{{ item.name }}
						</div>
					</div>
				</div>
				<div class="actionitem ">
					<div class="aleft">项目性质：</div>
					<div class="aright">
						<div @click="changeurl(item.id, attribute, 'attribute')" :class="{ sinle: true, checked: item.checked }"
							v-for="item in attribute" :key="item.id">{{ item.name }}
						</div>
					</div>
				</div>
				<div class="actionitem ">
					<div class="aleft">使用年限：</div>
					<div class="aright">
						<div @click="changeurl(item.id, life, 'life')" :class="{ sinle: true, checked: item.checked }"
							v-for="item in life" :key="item.id">{{ item.name }}</div>
					</div>
				</div>
				<div class="actionitem ">
					<div class="aleft">交易方式：</div>
					<div class="aright">
						<div @click="changeurl(item.id, trade, 'trade')" :class="{ sinle: true, checked: item.checked }"
							v-for="item in trade" :key="item.id">{{ item.name }}
						</div>
					</div>
				</div>
				<div class="actionitem ">
					<div class="aleft">用地面积：</div>
					<div class="aright">
						<div @click="changeurl(item.id, extent, 'extent')" :class="{ sinle: true, checked: item.checked }"
							v-for="item in extent" :key="item.id">{{ item.name }}
						</div>
					</div>
				</div>
			</div>

			<div class="list" v-if="list.length > 0">
				<div class="bingoitem" v-for="item in list" :key="item.id">
					<img class="cover" @click="todetail(item)" style="cursor:pointer" :src="item.cover" alt="">
					<div class="rightlist">
						<div class="title" @click="todetail(item)" style="cursor:pointer">{{ item.title }}</div>
						<div class="line">
							<div class="lineLift">项目编号:</div>
							<div class="lineRight">{{ item.number }}</div>
						</div>
						<div class="line">
							<div class="lineLift">用地面积:</div>
							<div class="lineRight">{{ item.extent_value }}m²</div>
						</div>
						<div class="line">
							<div class="lineLift">项目性质:</div>
							<div class="lineRight">{{ item.attribute_data }}</div>
						</div>
						<div class="line">
							<div class="lineLift">使用年限:</div>
							<div class="lineRight">{{ item.life_data }}</div>
						</div>
						<div class="line">
							<div class="lineLift">容&nbsp;积&nbsp;率&nbsp;:</div>
							<div class="lineRight">{{ item.volumn }}</div>
						</div>
						<div class="line trademode">
							<div class="lineLift">交易方式:</div>
							<div class="lineRight">{{ item.trade_data }}</div>
						</div>
						<div class="line">
							<div class="lineLift">交易报价:</div>
							<div class="lineRight">{{ item.offer }}</div>
						</div>
						<div class="btn" v-if="item.data" @click="apply(item)">
							{{ item.data_apply_examine_text }}
						</div>
					</div>


				</div>
				<el-pagination style="margin-top:20px;" @current-change="handleCurrentChange" :current-page="query.page"
					:page-size="query.size" background layout="prev, pager, next" :total="total">
				</el-pagination>

			</div>
			<div class="list" v-if="list.length === 0">
				暂无数据
			</div>
		</div>
		<div class="right">
			<div class="jujianright">
				<div class="nong">
					<div class="nongtitle">
						<blue-title>农地流转</blue-title>

					</div>
					<div class="miaoshu">
						<div class="lefttext">耕地，林地，养殖用地，其他用地</div>
						<div class="righttext" @click="toNong">免费发布 >></div>
					</div>
					<div class="nonglist">
						<div class="nonglistitem" @click="todetail(item)" v-for="item in nonglist" :key="item.id">{{ item.title }}
						</div>
					</div>
				</div>



			</div>
			<div class="ad">
				<img class="cover" @click="towindow(addata.list_link)" :src="addata.list_file_url" />
			</div>

			<div class="zhuce" @click="toReg">
				<img class="icon" src="@/assets/images/jjrzc.png" alt="">
				<div class="text">{{ btnname }}</div>
			</div>
			<div class="denglu">
				<div class="leftdenglu" @click="toReg">
					<img class="icon" src="@/assets/images/fbxm.png" alt="">
					<div class="text">发布项目</div>
				</div>
				<div class="leftdenglu" @click="toXu">
					<img class="icon" src="@/assets/images/fbxq.png" alt="">
					<div class="text">发布需求</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import { hotCity, apply, ad, bingoHomeList, bingoHomeListForLogin, nongHomeList, enumList } from '@/api/website/bingo'
import BlueTitle from '@/components/website/BlueTitle.vue';
import { downloadZip } from '@/api/cityresearch'

import store from '@/store'
export default {
	components: { BlueTitle },
	name: 'PcWebsiteJujian',

	data() {
		return {
			btnname: '注册经纪人',
			addata: {},
			nonglist: [],
			hotcity: [],
			attribute: [],
			life: [],
			trade: [],
			extent: [],
			total: 0,
			list: [],
			Loading: {},
			query: {
				city_id: '',
				attribute: '',
				life: '',
				trade: '',
				extent: '',
				page: 1,
				size: 10
			}
		};
	},

	watch: {
		$route: {
			handler(route) {
				if (JSON.stringify(route.query) !== '{}') {
					let query = route.query
					this.handleQuery(query)

					this.conlist()
				}
			},
			deep: true,
			immediate: true
		}
	},
	computed: {
		site: {
			get() {
				return this.$store.state.site
			},
			set(v) {
				this.$store.dispatch('site', v);
			}
		}
	},

	mounted() {

		if (JSON.stringify(this.$route.query) !== '{}') {
			let query = this.$route.query
			this.handleQuery(query)

		}



		if (store.state.user.token != null) {
			this.btnname = "我的发布"
		} else {
			this.btnname = "我要成为经纪人"
		}

		this.init()

	},


	methods: {
		toReg() {
			let tourl;
			if (store.state.user.token != null) {
				// this.btnname = "我的发布"
				tourl = '/' + this.site.path + '/list/addbingo'
			} else {
				// this.btnname = "我的经纪人"
				tourl = '/' + this.site.path + '/list/jjrzc'
			}



			this.$router.push(tourl)
		},

		apply(data) {

			var that = this
			if (store.state.user.token == null) {
				// const tourl = '/' + this.site.path + '/list/jjrzc'
				window.location.href = process.env.VUE_APP_LOGIN_URL + '?url=' + window.btoa(unescape(encodeURIComponent(window.location)))

				// this.$router.push(tourl)
			} else {

				const params = { id: data.id }

				if (data.data_apply_examine === 1) {
					this.downloadZip(data)
				} else {
					apply(params).then(res => {
						if (res.code === 1) {
							// if (data.data_apply_examine === 0) {
							that.conlist()
							// }
						}
					})
				}
			}


		},


		startLoading() {
			this.Loading = this.$loading({
				spinner: "",//自定义加载图标类名
				text: "数据正在打包中...",//显示在加载图标下方的加载文案
				background: "rgba(0, 0, 0, 0.3)",//遮罩背景色
			})
		},


		downloadZip(data) {
			this.startLoading()
			const params = {
				file_ids: data.data
			}
			downloadZip(params).then(res => {
				window.location.href = res.data
				this.Loading.close();
			})
		},


		toXu() {
			const tourl = '/' + this.site.path + '/list/addxuqiu'
			this.$router.push(tourl)
		},
		toNong() {
			const tourl = '/' + this.site.path + '/list/addnong'
			this.$router.push(tourl)
		},




		todetail(item) {
			const tourl = '/' + this.site.path + '/detail/jujian/' + item.id
			// this.$router.push(tourl)
			let { href } = this.$router.resolve({ path:tourl })
			window.open(href, '_blank')
		},



		changeurl(id, field, strfiled) {
			field.map(item => {
				if (item.id === id) {
					item.checked = true
				} else {
					item.checked = false
				}
				return item
			})
			this.query.page = 1
			this.query[strfiled] = id
			const query = this.handleOBJtoQuery(this.query)
			this.$router.push('/cn/list/jujian?' + query)
		},

		handleQuery(query) {

			if (query.city_id != null) {
				this.query.city_id = Number(query.city_id)
			}
			if (query.page != null) {
				this.query.page = Number(query.page)
			}
			if (query.size != null) {
				this.query.size = Number(query.size)
			}
			if (query.attribute != null) {
				this.query.attribute = Number(query.attribute)
			}
			if (query.life != null) {
				this.query.life = Number(query.life)
			}
			if (query.trade != null) {
				this.query.trade = Number(query.trade)
			}
			if (query.extent != null) {
				this.query.extent = Number(query.extent)
			}

		},


		handleOBJtoQuery(obj) {
			let str = ''
			if (obj.city_id) {
				str += 'city_id=' + obj.city_id + '&'
			} else {
				str += 'city_id=&'
			}
			if (obj.page) {
				str += 'page=' + obj.page + '&'
			} else {
				str += 'page=&'
			}
			if (obj.size) {
				str += 'size=' + obj.size + '&'
			} else {
				str += 'size=&'
			}
			if (obj.attribute) {
				str += 'attribute=' + obj.attribute + '&'
			} else {
				str += 'attribute=&'
			}
			if (obj.life) {
				str += 'life=' + obj.life + '&'
			} else {
				str += 'life=&'
			}
			if (obj.extent) {
				str += 'extent=' + obj.extent + '&'
			} else {
				str += 'extent=&'
			}
			if (obj.trade) {
				str += 'trade=' + obj.trade + '&'
			} else {
				str += 'trade=&'
			}

			return str;
		},

		handleCurrentChange(page) {

			this.query.page = page

			const query = this.handleOBJtoQuery(this.query)

			this.$router.push('/cn/list/jujian?' + query)

		},

		conlist() {

			if (store.state.user.token == null) {
				// alert(1)
				bingoHomeList(this.query).then(res => {
					this.list = res.data.list
					this.total = res.data.total
				})
			} else {
				// alert(2)
				bingoHomeListForLogin(this.query).then(res => {
					this.list = res.data.list
					this.total = res.data.total
				})
			}


		},


		towindow(url) {
			window.location.href = url
		},
		init() {
			ad().then(res => {
				this.addata = res.data[0]
			})

			nongHomeList().then(res => {
				this.nonglist = res.data
			})

			enumList({ is_all: 1 }).then(res => {
				this.attribute = res.data.attribute
				this.life = res.data.life
				this.trade = res.data.trade
				this.extent = res.data.extent
			})

			hotCity().then(res => {
				this.hotcity = res.data
			})
			this.conlist()

		},



	},
};
</script>

<style lang="scss" scoped>
.ad {
	margin-top: 20px;
	width: 356px;
	height: 190px;

	.cover {
		width: 356px;
		height: 190px;
	}
}

.jujianright {
	width: 356px;
	min-height:120px;
	background: #FFFFFF;
	// overflow: hidden;



	.nong {



		.miaoshu {
			display: flex;
			justify-content: space-between;
			justify-items: center;
			width: 336px;
			height: 26px;
			background: rgba(231, 120, 23, 0.1);
			border-radius: 2px;
			margin-left: 10px;

			.lefttext,
			.righttext {
				height: 26px;
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #E77817;
				line-height: 26px;
				margin-left: 15px;
				margin-right: 15px;
			}

			.righttext {
				cursor: pointer;
			}
		}
	

		.nonglist {
			display: flex;
			flex-direction: column;
			width: 336px;

			padding-left: 10px;
			box-sizing: border-box;

			.nonglistitem {
				cursor: pointer;
				width: 336px;
				height: 47px;
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #000000;
				line-height: 47px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				border-bottom: solid 1px #F0F3F8;
				text-align: left;

				&:last-child {
					border-bottom: none;
				}
			}

		}



	}




}

.zhuce {
	margin-top: 20px;
	width: 356px;
	height: 66px;
	background: rgba(0, 163, 228, 0.1);
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

}

.icon {
	width: 36px;
	height: 36px;
	margin-right: 10px;
}

.text {
	height: 22px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #000000;
}

.denglu {
	width: 356px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 10px;

	.leftdenglu {
		cursor: pointer;
		width: 170px;
		height: 66px;
		background: rgba(0, 163, 228, 0.1);
		display: flex;
		justify-content: center;
		align-items: center;


	}

}


.jujianlist {
	display: flex;
}

.left {
	width: 830px;
	height: auto;
	margin-right: 14px;

	.action {
		width: 830px;
		min-height: 190px;
		background: #FFFFFF;
		padding: 20px;
		box-sizing: border-box;

		.actionitem {
			display: flex;
			align-items: flex-start;
			margin-bottom: 10px;

			.aleft {
				width: 80px;
				height: 22px;
				font-size: 16px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #000000;
				line-height: 22px;

			}


			.aright {
				display: flex;
				flex-wrap: wrap;
				flex:1;
				.sinle {
					margin-bottom: 10px;
					height: 20px;
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #000000;
					line-height: 20px;
					margin-left: 5px;
					margin-right: 20px;
					cursor: pointer;
				}

				.checked {
					color: #00A3E4;
				}
			}
		}
	

	}

	.list {
		margin-top: 20px;
		padding-bottom: 20px;
		width: 830px;
		min-height: 600px;
		background: #FFFFFF;

		.bingoitem {
			display: flex;
			height: 281px;
			justify-content: flex-start;
			align-items: center;
			margin-left: 20px;
			margin-right: 20px;

			border-bottom: solid 2px #f2f2f2;


			.cover {
				width: 380px;
				height: 240px;
				margin-right: 20px;
				border: solid 1px #dedede;
			}


			.rightlist {
				height: 240px;
				flex: 1;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				// background:red;
				align-items: flex-start;

			

				.line {
					display: flex;

					.lineLift {
						margin-right: 20px;
						height: 17px;
						font-size: 12px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #000000;
						line-height: 17px;
					}
				}
				.trademode {
					display: flex;

					.lineLift {
						margin-right: 20px;
						height: 17px;
						font-size: 12px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 800;
						color: #000000;
						line-height: 17px;
					}
					.lineRight{
						line-height: 17px;
						font-weight: 800;
					}
				}

				.btn {
					// width: 144px;
					display: inline-block;
					padding: 0 15px;
					height: 26px;
					line-height: 26px;
					background: #FFFFFF;
					border: 1px solid #00A3E4;
					cursor: pointer;
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #00A3E4;


				}
			}

		}

	}

}

.right {
	width: 356px;
	min-height: 824px;
}
.lineRight{
			line-height: 17px;
		}
		.title {
			text-align: left;
			padding-right:20px;
			box-sizing: border-box;
			width: 100%;
			height: 22px;
			font-size: 16px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #000000;
			line-height: 22px;
		}
</style>